import React, { useEffect, useContext } from "react";
import ClickOutsideListener from "components/ClickOutsideListener";
import Icon from "components/Icon";
import { useTranslations } from "lib/translations";
import DrawerContext from "./DrawerContext";
import useValueObserver from "./useValueObserver";
import classnames from "classnames";

import styles from "./index.module.scss";

function Drawer({
  isOpened,
  children,
  onClose,
  className,
  closeOnOutsideClick,
}) {
  const { setCurrentDrawer, setIsOpened } = useContext(DrawerContext);
  const { translate } = useTranslations();

  useValueObserver(isOpened, () => {
    setIsOpened(isOpened);
  });

  useValueObserver(children, () => {
    setCurrentDrawer(
      <ClickOutsideListener
        onClickOutside={
          isOpened && closeOnOutsideClick
            ? () => {
                onClose();
              }
            : undefined
        }
      >
        <div
          className={classnames(styles.drawerWrapperBody, className)}
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className={styles.drawerBody}>
            <div className={styles.drawerButtons}>
              <button className={styles.drawerButtonsClose} onClick={onClose}>
                <Icon
                  iconName="close"
                  className={styles.drawerButtonsCloseIcon}
                />
                <span className={styles.drawerButtonsCloseText}>
                  {translate("close")}
                </span>
              </button>
            </div>
            {children}
          </div>
        </div>
      </ClickOutsideListener>
    );
  });

  useEffect(() => {
    return () => {
      setIsOpened(false);
    };
  }, []);

  return null;
}

export { default as DrawerProvider } from "./DrawerProvider";

export default Drawer;
