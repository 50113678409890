import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";

import DrawerContext from "./DrawerContext";

import styles from "./index.module.scss";

function DrawerProvider({ children }) {
  const [currentDrawer, setCurrentDrawer] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  const contextValue = useMemo(
    () => ({
      setCurrentDrawer,
      setIsOpened,
    }),
    []
  );

  useEffect(() => {
    if (isOpened) {
      document.body.style = "overflow: hidden; touch-action: none;";
    }

    return () => {
      if (isOpened) {
        document.body.style = "";
      }
    };
  }, [isOpened]);

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
      <div
        className={classNames(styles.drawer, {
          [styles.isOpened]: isOpened,
        })}
      >
        <div className={styles.drawerMask}></div>
        <div className={styles.drawerContent}>{currentDrawer}</div>
      </div>
    </DrawerContext.Provider>
  );
}

export default DrawerProvider;
