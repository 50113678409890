import React from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

const Icon = ({ iconName, className, ...props }) => (
  <svg {...props} className={classNames(styles.container, className)}>
    <use key={iconName} xlinkHref={`/sprite.svg#${iconName}`} />
  </svg>
);

export default Icon;
