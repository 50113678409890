import React from "react";

import TranslationsContext from "./context";

function TranslationsProvider({ children, translations, locale }) {
  const context = React.useMemo(
    () => ({
      translations,
      locale,
    }),
    [translations, locale]
  );

  return (
    <TranslationsContext.Provider value={context}>
      {children}
    </TranslationsContext.Provider>
  );
}

export default TranslationsProvider;
