export { default as useTranslations } from "./useTranslations";
export { default as TranslationsProvider } from "./TranslationsProvider";

export async function loadTranslations(locale) {
  const translations = await import(`./languages/${locale}.json`);
  return {
    locale,
    translations: translations.default,
  };
}
