import { useEffect, useRef } from "react";

function useValueObserver(value, callback) {
  const prevValueRef = useRef();
  const callbackRef = useRef();

  callbackRef.current = callback;

  useEffect(() => {
    if (prevValueRef.current !== value) {
      callbackRef.current(prevValueRef.current, value);
      prevValueRef.current = value;
    }
  }, [value]);
}

export default useValueObserver;
