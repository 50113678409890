import { useContext, useCallback } from "react";
import { sprintf } from "sprintf-js";

import translationContext from "./context";

function useTranslations() {
  const context = useContext(translationContext);

  const translate = useCallback(
    (key, ...args) => {
      if (context.translations[key]) {
        return sprintf(context.translations[key], ...args);
      }
    },
    [context.translations]
  );

  const getLocaleField = useCallback(
    (obj, field) => {
      if (obj) {
        return Reflect.get(obj, `${field}_${context.locale}`);
      }
    },
    [context.locale]
  );

  return {
    ...context,
    getLocaleField,
    translate,
  };
}

export default useTranslations;
