import { useRef, useEffect, cloneElement } from "react";

function ClickOutsideListener({ onClickOutside, children }) {
  const mountedRef = useRef(false);
  const childrenRef = useRef(undefined);

  useEffect(() => {
    mountedRef.current = true;

    function clickOutsideListener(event) {
      if (!mountedRef.current || !childrenRef.current) {
        return;
      }

      if (!childrenRef.current.contains(event.target)) {
        onClickOutside(event);
      }
    }

    if (document) {
      document.addEventListener("click", clickOutsideListener);
    }

    return () => {
      if (document) {
        document.removeEventListener("click", clickOutsideListener);
      }
      mountedRef.current = false;
    };
  }, [onClickOutside]);

  return cloneElement(children, { ref: childrenRef });
}

ClickOutsideListener.defaultProps = {
  onClickOutside: () => undefined,
};

export default ClickOutsideListener;
